<template>
  <div
    class="available-parts"
  >
    <div
      class="available-parts__headline--wrapper"
      :id="paginationId"
    >
      <div class="available-parts__headline h2">
        <template v-if="isBundle">
          {{ $t('global.product.bundleParts') }}
        </template>
        <template v-else>
          {{ $t('global.product.available.parts') }}
        </template>
        <span class="available-parts__headline--availablePartsSize">({{ availablePartsSize }})</span>
      </div>
      <div
        v-if="isMobile"
      >
        <span class="available-parts__cell available-parts__cell--quality default-semi">
        <a
          :href="baseUrl + $t('global.product.quality.link')"
          v-t="'global.product.quality.mobile'"
        />
      </span>
      </div>
      <select-vue
        class="available-parts__sorting"
        ref="sortingData"
        :initial-value="sortingData.selectedOptionValue"
        :options="sortingData.options"
        :use-native-on-mobile="false"
        :use-custom-select="true"
        :key="sortingData.selectedOptionValue"
        @change="sortAvailableParts"
      />
    </div>
    <div
      v-if="!isMobile"
      class="available-parts__header"
    >
      <span class="available-parts__cell available-parts__cell--image default-semi-bold">
        {{ $t('global.product.images') }}
      </span>
      <span class="available-parts__cell available-parts__cell--quality default-semi-bold">
        <a
          :href="baseUrl + $t('global.product.quality.link')"
          target="_blank"
          v-t="'global.product.quality'"
        />
      </span>
      <span
        v-if="isBundle"
        class="available-parts__cell available-parts__cell--damage-model default-semi-bold"
      >
        {{ $t('global.product.damageDescription') }}
      </span>
      <span
        v-if="!isBundle"
        class="available-parts__cell available-parts__cell--numbers default-semi-bold"
      >
        {{ $t('global.product.included.numbers') }}
      </span>
      <span
        v-if="!isBundle"
        class="available-parts__cell available-parts__cell--price default-semi-bold"
      >
        {{ $t('global.product.price') }}
      </span>
      <span
        v-if="!isBundle"
        class="available-parts__cell available-parts__cell--basket"
      />
    </div>
    <accordion
      v-for="(availablePart, i) in availableParts"
      :mobile-only="true"
      :show-button="isMobile"
      :id="availablePart.id"
      ref="accordion"
      :name="availablePartAccordionButton(availablePart)"
      :key="`available-parts__accordion${i}`"
      class="available-parts__accordion"
    >
      <template #content>
        <div
          class="available-parts__list-wrapper"
        >
          <available-part
            :id="`available-part__${i}`"
            :available-part="availablePart"
            :key="`available-part__${i}`"
            :is-mobile="isMobile"
            :is-bundle="isBundle"
          />
          <available-part-modal
            :available-part="availablePart"
            :id="availablePart.id"
            :key="`available-part-modal__${i}`"
          />
        </div>
      </template>
    </accordion>
    <div class="pagination__bottom-bar">
      <pagination
        :pagination="pagingData"
        :show-go-to="false"
        :show-items-per-page="true"
        :max-size="availablePartsSize.toString()"
        :id="paginationId"
        @next-page="nextPageHandler"
        @prev-page="prevPageHandler"
        @elements-per-page="elementsPerPageHandler"
      />
    </div>
  </div>
</template>

<script>
import productPageStateComputedMixin from "../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin";
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import Accordion from "../accordion/accordion.vue";
import {breakpoints} from "../../variables/variables";
import AvailablePart from "./availablePart.vue";
import Pagination from "../pagination/pagination.vue";
import {mapGetters} from "vuex";
import SelectVue from "../select/select.vue";
import UrlParameterMixin from "../../utilities/vueMixins/urlParameterMixins/urlParameterMixin";
import AvailablePartModal from "./availablePartModal.vue";
import {toRaw} from "vue";

export default {
  components: {
    Pagination,
    Accordion,
    AvailablePart,
    AvailablePartModal,
    SelectVue
  },
  mixins: [
    productPageStateComputedMixin,
    globalStateComputedMixin,
    UrlParameterMixin
  ],
  props: {
    isBundle: {
      type: Boolean,
      default: false
    },
  },
  created() {
    const splitElement = 'productId=';
    const urlParameters = window.location.href.split(splitElement)[1]
    if (urlParameters !== undefined) {
      let params = new URLSearchParams(splitElement + urlParameters);
      const createdParameters = this.paramsToObject(params.entries());
      this.$store.dispatch('productPage/initialPageUpdate', createdParameters);
    }
    this.bindHistoryEvent();
    this.saveInitialHistoryState();
  },
  data() {
    return {
      isMobile: window.innerWidth < breakpoints.tablet,
      paginationId: 'availableParts'
    };
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  computed: {
    ...mapGetters('productPage', [])
  },
  methods: {
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.tablet;
    },
    availablePartAccordionButton(part) {
      const bundleName = part.bundleName ? `<span class="default-semi-bold">${part.bundleName}</span><br>` : '';
      const priceString = part.price?.displayValue ? `<span class="default-regular">${part.price.displayValue}</span>` : '';
      return bundleName + `<span class="default-regular">${this.$t('global.product.quality')}: ${part.qualityClass}</span>`
        + priceString
    },
    bindHistoryEvent() {
      window.onpopstate = (event) => {
        this.$store.dispatch('productPage/historyPopState', event);
      };
    },
    nextPageHandler() {
      this.$store.dispatch('productPage/nextPage');
    },
    prevPageHandler() {
      this.$store.dispatch('productPage/prevPage');
    },
    elementsPerPageHandler(payload) {
      this.$store.dispatch('productPage/itemsPerPage', payload);
    },
    sortAvailableParts(payload) {
      this.$store.dispatch('productPage/sortProducts', payload);
    },
    saveInitialHistoryState() {
      window.history.replaceState(
        {
          availableParts: toRaw(this.$store.state.productPage.productData.availableParts),
          pagingData: toRaw(this.$store.state.productPage.productData.pagingData),
          sortingData: toRaw(this.$store.state.productPage.productData.sortingData)
        },
        document.title,
        window.location.href
      );
    }
  }
};
</script>
